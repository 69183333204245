import React from "react";
import { Container, Box, Typography, Link, CardMedia } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import LinkBar from "../components/linkbar";
import Grid from "@material-ui/core/Grid";
import { Button } from "gatsby-material-ui-components";
import banner from "./banner.png";
import ExternalLink from "../components/external-link";
import StyledLink from "../components/styled-link";
import SearchBar from "material-ui-search-bar";
import { navigate } from "@reach/router";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "2px 4px",
      display: "flex",
      alignItems: "center",
      width: 400,
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },

    spacer: {
      "& a": {
        margin: theme.spacing(0, 2, 0, 0),
      },
      margin: theme.spacing(0, 0, 2, 0),
    },

    banner: {
      margin: theme.spacing(0, 0, 4, 0),
      padding: theme.spacing(2, 0, 4, 0),
      background: `#004775`,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },

    bannerContainer: {
      maxWidth: "900px",
      padding: theme.spacing(0, 4, 0, 4),
      color: `#B35509`,
    },

    searchBar: {
      minWidth: "660px", // intended to be same width as placeholder
    },

    bannerButton: {
      margin: theme.spacing(0, 2, 0, 2),
      borderRadius: 25,
    },

    bannerButtonText: {
      color: `#fff`,
    },

    findAReproductionHeader: {
      margin: theme.spacing(4, 0, 2, 0),
    },

    bodySubheader: {
      margin: theme.spacing(4, 0, 0, 0),
      color: `#004775`,
    },
  })
);

function Banner() {
  const classes = useStyles();

  const [searchTerm, setSearchTerm] = React.useState<string>("");

  const searchPapers = async () => {
    navigate(`/reproductions/search?query=${searchTerm}`);
  };

  return (
    <>
      <Grid container className={classes.banner}>
        <Box className={classes.bannerContainer} mt={2}>
          <CardMedia component="img" src={banner}></CardMedia>
        </Box>
        <Box align={"center"}>
          <Button
            size={"large"}
            variant={"contained"}
            color={"primary"}
            className={classes.bannerButton}
            href={"/reproductions/home"}
          >
            <span className={classes.bannerButtonText}>Get started!</span>
          </Button>
        </Box>
        <Box mt={2} className={classes.searchBar}>
          <SearchBar
            placeholder="Search reproductions by paper title, DOI, or author. Do a blank search to see all."
            cancelOnEscape={true}
            value={searchTerm}
            onChange={(newValue) => setSearchTerm(newValue)}
            onRequestSearch={searchPapers}
          />
        </Box>
      </Grid>
    </>
  );
}

function MainBody() {
  const classes = useStyles();
  return (
    <>
      <Typography variant="body1" component="div" gutterBottom>
        <p>
          The Social Science Reproduction Platform (SSRP) crowdsources and
          catalogs attempts to <strong>assess</strong> and{" "}
          <strong>improve</strong> the computational reproducibility of social
          science research. Instructors can use the SSRP in applied social
          science courses at the graduate or undergraduate levels to teach
          fundamental concepts, methods, and reproducible research practices.{" "}
          <StyledLink href="/about">Learn More →</StyledLink>
        </p>
      </Typography>
      <Typography variant="h1">How it works</Typography>
      <Box display="flex">
        <Box mt={4} pr={4}>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/eBNjICkkgGM"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Box>
        <Box>
          <Typography
            variant="h2"
            gutterBottom
            className={classes.bodySubheader}
          >
            Assess & Improve
          </Typography>
          <Typography variant="body1" component="p" gutterBottom>
            Users can assess and improve computational reproducibility through a
            structured four-stage process using our{" "}
            <ExternalLink href={"https://bitss.github.io/ACRE/"}>
              Guide for Accelerating Computational Reproducibility
            </ExternalLink>
            .
          </Typography>
          <Typography
            variant="h2"
            gutterBottom
            className={classes.bodySubheader}
          >
            Review & Collaborate
          </Typography>
          <Typography variant="body1" component="p" gutterBottom>
            In the{" "}
            <ExternalLink href={"https://forum.socialsciencereproduction.org/"}>
              SSRP Forum
            </ExternalLink>
            , users can review, comment on, and collaborate on reproductions
            submitted by others. To join the discussion about a particular
            paper, look up its DOI in the{" "}
            <ExternalLink href="https://forum.socialsciencereproduction.org/c/reproductions/6">
              Reproductions
            </ExternalLink>{" "}
            category.
          </Typography>
          <Typography
            variant="h2"
            gutterBottom
            className={classes.bodySubheader}
          >
            Measure
          </Typography>
          <Typography variant="body1" component="p" gutterBottom>
            The <StyledLink href="/metrics">Metrics Dashboard</StyledLink>{" "}
            aggregates data from all reproductions submitted to the platform by
            students and researchers around the world.
          </Typography>
        </Box>
      </Box>
    </>
  );
}

export default function App() {
  return (
    <>
      <LinkBar />
      <Banner />
      <Container maxWidth="lg">
        <Box my={4}>
          <MainBody />
        </Box>
      </Container>
    </>
  );
}
