import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Link } from "gatsby-theme-material-ui";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    linkBarContainer: {
      width: "100%",
      height: "62px",
    },
    spacer: {
      backgroundColor: `#FF8000`,
      height: "100%",
      width: "100%",
      padding: "2px 62px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    linkBox: {
      height: "100%",
      width: 0,
      flexGrow: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    linkBoxSelected: {
      backgroundColor: `#D6700A`,
      height: "100%",
      width: 0,
      flexGrow: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    link: {
      color: `#fff`,
      fontSize: `1.25rem`, // same as h4
      fontWeight: "bold",
      letterSpacing: "1px",
    },
  })
);

export default function LinkBar({ selected }: { selected: string }) {
  const classes = useStyles();
  const linkBoxStyling = (tabName) =>
    selected === tabName ? classes.linkBoxSelected : classes.linkBox;
  return (
    <Box className={classes.linkBarContainer}>
      <Box className={classes.spacer} component="div" flexDirection="row">
        <Box className={classes.linkBox}>
          <Link to="/" className={classes.link}>
            Home
          </Link>
        </Box>
        <Box className={linkBoxStyling("About")}>
          <Link to="/about" className={classes.link}>
            About
          </Link>
        </Box>
        <Box className={linkBoxStyling("Events")}>
          <Link to="/events" className={classes.link}>
            Events
          </Link>
        </Box>
        <Box className={linkBoxStyling("Guide")}>
          <Link to="/guide" className={classes.link}>
            Guide
          </Link>
        </Box>
        <Box className={linkBoxStyling("Forum")}>
          <Link
            to="https://forum.socialsciencereproduction.org"
            className={classes.link}
          >
            Forum
          </Link>
        </Box>
        <Box className={linkBoxStyling("Metrics")}>
          <Link to="/metrics" className={classes.link}>
            Metrics
          </Link>
        </Box>
      </Box>
    </Box>
  );
}
